import React from 'react'
import { graphql } from 'gatsby'
import orderBy from 'lodash/orderBy'
import Helmet from 'react-helmet'
import moment from 'moment'
import config from '../utils/siteConfig'
import Layout from '../components/Layout'
import Card from '../components/Card'
import CardList from '../components/CardList'
import Pagination from '../components/Pagination'
import Subscribe from '../components/Subscribe'
import MaxWidth from '../components/MaxWidth'
import CategoryMenu from '../components/CategoryMenu'
import { sortBy } from 'lodash'
import { Grid } from 'semantic-ui-react'

const CategoryTemplate = ({ data, pageContext }) => {
  const posts = orderBy(
    data.contentfulCategory.post,
    [object => new moment(object.publishDateISO)],
    ['desc']
  )

  const { title, definition, slug } = data.contentfulCategory
  const numberOfPosts = posts.length
  const skip = pageContext.skip
  const limit = pageContext.limit
  const currentPage = pageContext.currentPage
  const isFirstPage = currentPage === 1

  // Move current category to front of array for each post
  posts.map(post => (
    post.categories = sortBy(post.categories, function(category) {
      return category.slug === slug ? 0 : 1
    })
  ))

  return (
    <Layout>
      {isFirstPage ? (
        <Helmet>
          <title>{`Category: ${title} - ${config.siteTitle}`}</title>
          <meta
            property="og:title"
            content={`Category: ${title} - ${config.siteTitle}`}
          />
          <meta property="og:url" content={`${config.siteUrl}/category/${slug}/`} />
        </Helmet>
      ) : (
        <Helmet>
          <title>{`Category: ${title} - Page ${currentPage} - ${
            config.siteTitle
          }`}</title>
          <meta
            property="og:title"
            content={`Category: ${title} - Page ${currentPage} - ${
              config.siteTitle
            }`}
          />
          <meta property="og:url" content={`${config.siteUrl}/category/${slug}/`} />
        </Helmet>
      )}
      <section id="page-categories">
        <MaxWidth>
          <CategoryMenu activeCategory={slug} />
        </MaxWidth>
      </section>
      <section id="page-content">
        <MaxWidth>
          <CardList>
            {posts.slice(skip, limit * currentPage).map(post => (
              <Card {...post} key={post.id} />
            ))}
          </CardList>
          <Pagination context={pageContext} />
        </MaxWidth>
      </section>
      <section className="page-subscribe">
        <Subscribe />
      </section>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    contentfulCategory(slug: { eq: $slug }) {
      title
      definition
      id
      slug
      post {
        id
        title
        slug
        publishDate(formatString: "MMMM DD, YYYY")
        publishDateISO: publishDate(formatString: "YYYY-MM-DD")
        categories {
          title
          id
          slug
          color
        }
        tags {
          title
          id
          slug
        }
        body {
          childMarkdownRemark {
            timeToRead
            html
            excerpt(pruneLength: 200)
          }
        }
      }
    }
  }
`

export default CategoryTemplate
